.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
body {
    font-family: 'Roboto';
}

.title-bar {
    height: 60px;
    border-bottom: 1px solid #e6e4ea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.subtitle {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.input-box {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
}
.MainLayout-relativeContainer > .Container-container {
    /* This insanity is needed to make the table cells clickable */
    pointer-events: none;
}
.MainLayout-relativeContainer > .Container-container > div {
    pointer-events: auto;
}

.HorizontalAppointment-title {
    white-space: normal;
}

.custom-cell:hover {
    background-color: #f0edf7;
}

.copy-button {
    color: white;
}

.copy-button:hover {
    color: grey;
}

.main-button {
    width: '95%';
}

@media (min-width: 1000px) {
    .whole-contents .shrinkable {
        margin: 1% 35% 0% 35%;
    }
    .title-bar {
        margin: 5rem 20rem 0.5rem 20rem;
    }
    .subtitle {
        margin: 5rem 20rem 0.5rem 20rem;
    }
    .main-button {
        width: 620px;
    }
}
@media (max-width: 600px) {
    .title-bar {
        flex-direction: row;
    }
}